import { createReducer } from "@reduxjs/toolkit";

import { UserReducerType } from "./user.types";
import {
  CLEAR_USER_PROFILE,
  SET_USER_CURRENT_ZOOM,
  SET_USER_POSITION_INFORMATION,
  SET_USER_PROFILE,
} from "./user.actions";
import Mixpanel from "../../../util/Mixpanel";

const initialState: UserReducerType = {
  profile: {
    id: null,
    date_joined: null,
    email: null,
    expiry_date: null,
    first_name: null,
    last_login: null,
    last_name: null,
    reports_limit: null,
    username: null,
    simple_mode_enabled: true,
    can_fetch_alerts: false,
    organization: null,
    location: null,
  },
  positionInformation: null,
  currentZoom: 15,
};

export default createReducer(initialState, {
  [SET_USER_PROFILE]: (state, action) => {
    state.profile = action.payload;
    Mixpanel.getInstance().setUser(state.profile);
  },
  [SET_USER_POSITION_INFORMATION]: (state, action) => {
    state.positionInformation = action.payload;
  },
  [SET_USER_CURRENT_ZOOM]: (state, action) => {
    state.currentZoom = action.payload;
  },
  [CLEAR_USER_PROFILE]: (state) => {
    state.profile = initialState.profile;
  },
});
