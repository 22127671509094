import {
  AttributeConfiguration,
  AttributeMetadata,
  ColorValueRange,
  UserConfiguration,
} from "../models";
import BaseApi from "./BaseApi";
import { AttributeMetadataDetails } from "../models/AttributeMetadata";
import i18n from "i18next";
import { CompositeConfiguration } from "../../recoil/states/currentAttributeCompositeState";

export interface IOverlayResponse {
  heatmap: string;
  colorValueRanges: ColorValueRange[];
  isFloatingPointData: boolean;
  latNE: number;
  lonNE: number;
  latSW: number;
  lonSW: number;
}

interface IConfigurationResponse {
  userConfigurations: any[];
}

class SQMApi extends BaseApi {
  constructor() {
    super();
    this.transformAttributeConfigurationDictToInstances = this.transformAttributeConfigurationDictToInstances.bind(
      this,
    );
    this.transformUserConfigurationDictsToInstances = this.transformUserConfigurationDictsToInstances.bind(
      this,
    );
  }

  public async getUserCompositions() {
    const endpoint = "api/layers/composite/";
    return this.getAuthorizedWithEndpoint(endpoint).then(
      async (response: Response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Status is not 200.");
        }
      },
    );
  }

  public async saveUserCompositions(
    name: string,
    config: any[],
  ): Promise<void> {
    const endpoint = "api/layers/composite/";
    const bodyJSON = JSON.stringify({
      config,
      name,
    });
    await this.postAuthorized(endpoint, bodyJSON);
  }

  public async removeUserCompositions(id: string | number): Promise<void> {
    const endpoint = `api/layers/composite/${id}/`;
    await this.deleteAuthorized(endpoint, "");
  }

  public async updateUserComposition(
    id: string | number,
    data: any,
  ): Promise<void> {
    const endpoint = `api/layers/composite/${id}/`;
    await this.patchAuthorized(endpoint, JSON.stringify(data));
  }

  public async getAttributeDetails(attributeKey?: number): Promise<AttributeMetadataDetails> {
    const endpoint = `api/attributes/${attributeKey}/`;
    return this.getAuthorizedWithCustomHeaders(endpoint, { "accept-language": i18n.language })
      .then(async (response: Response) => {
        if (response.status === 200) {
          return await response.json();
        } else if (response.status === 400) {
          throw new Error(response.statusText);
        } else {
          throw new Error("Unspecified error.");
        }
      }).catch((reason: Error) => {
        throw new Error(reason.message);
      });
  }

  public async getAttributes(): Promise<[]> {
    const endpoint = `api/attributes/`;
    return this.getAuthorizedWithCustomHeaders(endpoint, { "accept-language": i18n.language })
      .then(async (response: Response) => {
        if (response.status === 200) {
          return await response.json();
        } else if (response.status === 400) {
          throw new Error(response.statusText);
        } else {
          throw new Error("Unspecified error.");
        }
      }).catch((reason: Error) => {
        throw new Error(reason.message);
      });
  }

  public async getHeatmap(
    attributes: AttributeConfiguration[],
    zoom: number,
    rated: boolean,
    scale: string,
    latNE: number,
    lonNE: number,
    latSW: number,
    lonSW: number,
    abortSignal?: AbortSignal,
  ): Promise<IOverlayResponse> {
    const queryString = `?attributes=${JSON.stringify(
      attributes,
    )}&zoom=${zoom}&scale=${scale}&latNE=${latNE}&lonNE=${lonNE}&latSW=${latSW}&lonSW=${lonSW}&rated=${rated}`;
    const endpoint = "api/heatmap/" + queryString;
    return this.getAuthorizedWithEndpoint(endpoint, "application/json", abortSignal)
      .then(async (response: Response) => {
        if (response.status === 200) {
          return await response.json();
        } else if (response.status === 400) {
          throw new Error(response.statusText);
        } else {
          throw new Error("Unspecified error.");
        }
      })
      .catch((reason: Error) => {
        throw new Error(reason.message);
      });
  }

  public async getHeatmapFromComposition(
    attributes: CompositeConfiguration[],
    zoom: number,
    latNE: number,
    lonNE: number,
    latSW: number,
    lonSW: number,
    id: number,
    abortSignal?: AbortSignal,
  ): Promise<IOverlayResponse> {
    const queryString = `?attributes=${JSON.stringify(
      attributes,
    )}&zoom=${zoom}&latNE=${latNE}&lonNE=${lonNE}&latSW=${latSW}&lonSW=${lonSW}`;
    const endpoint = `api/layers/composite/${id}/heatmap/` + queryString;
    return this.getAuthorizedWithEndpoint(endpoint, "application/json", abortSignal)
      .then(async (response: Response) => {
        if (response.status === 200) {
          return await response.json();
        } else if (response.status === 400) {
          throw new Error(response.statusText);
        } else {
          throw new Error("Unspecified error.");
        }
      })
      .catch((reason: Error) => {
        throw new Error(reason.message);
      });
  }

  public async getAttributeMetadata(): Promise<AttributeMetadata[]> {
    const endpoint = "api/metadata/";
    const response = await this.getAuthorizedWithCustomHeaders(endpoint, { "accept-language": i18n.language });
    const attributeMetadata: AttributeMetadata[] = this.transformAttributeMetaDataDictToInstances(
      await response.json(),
    );
    return attributeMetadata;
  }

  public async getLocalReportProxy(
    pathName: string,
    lat: number,
    lng: number,
    debug = false,
  ) {
    const endpoint = "api/localreport/";
    const body = {
      pathName,
      lat,
      lng,
      empty: debug,
    };
    return this.postAuthorized(endpoint, JSON.stringify(body))
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.blob();
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((reason: Error) => {
        throw new Error(reason.message);
      });
  }

  public async getLocalReportProxyV2(
    lat: number,
    lng: number,
  ) {
    const endpoint = "api/localreport/new/";
    const body = {
      "lat": lat,
      "lng": lng,
    };

    return this.postAuthorized(endpoint, JSON.stringify(body))
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.blob();
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((reason: Error) => {
        throw new Error(reason.message);
      });
  }

  public async getExistingLocalReportProxyV2(objectId: string){
    const endpoint = "api/localreport/pdf/";
    const body = {
      "object_id": objectId,
    };

    return this.postAuthorized(endpoint, JSON.stringify(body))
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.blob();
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((reason: Error) => {
        throw new Error(reason.message);
      });
  }

  public async getLocalReportAlerts(id: string){
    const endpoint = `api/objects/${id}/alerts/`;
    return this.postAuthorized(endpoint, "")
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.blob();
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((reason: Error) => {
        throw new Error(reason.message);
      });
  }

  public async getMyConfigurations(): Promise<UserConfiguration[]> {
    const endpoint = "api/configuration/";
    const configurationResponse = await this.getAuthorizedWithEndpoint(
      endpoint,
    );
    const plainUserConfigurations: IConfigurationResponse = await configurationResponse.json();
    const userConfigurations = this.transformUserConfigurationDictsToInstances(
      plainUserConfigurations.userConfigurations,
    );
    return userConfigurations;
  }

  public async saveMyConfiguration(
    name: string,
    configurations: AttributeConfiguration[],
  ): Promise<void> {
    const endpoint = "api/configuration/";
    const bodyJSON = JSON.stringify({
      configurations,
      name,
    });
    await this.postAuthorized(endpoint, bodyJSON);
  }

  public async deleteMyConfiguration(configurationId: number): Promise<void> {
    const endpoint = `api/configuration/${configurationId}/`;
    await this.deleteAuthorized(endpoint, "").then(
      async (response: Response) => {
        if (response.status >= 400) {
          const res = await response.json();
          throw new Error(res.details.join(" "));
        } else if (response.status >= 500) {
          throw new Error("Unexpected error on deleteMyConfiguration");
        }
      },
    );
  }

  public async getUserMarkers() {
    const endpoint = "api/markers/";
    return this.getAuthorizedWithEndpoint(endpoint).then(
      async (response: Response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Status is not 200.");
        }
      },
    );
  }

  public async getUserProfile() {
    const endpoint = "api/profile/";
    return this.getAuthorizedWithEndpoint(endpoint).then(
      async (response: Response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 403) {
          throw new Error("User not assigned to organization.");
        } else {
          throw new Error("Status is not 200.");
        }
      },
    );
  }

  public async getInformationForLocation(
    attributes: AttributeConfiguration[],
    lat: number,
    lng: number,
    isCsvContentType: boolean = false,
  ): Promise<Map<string, any> | Blob> {
    if (attributes.length === 0) {
      return new Map<string, any>();
    }
    const queryString = `?attributes=${JSON.stringify(
      attributes,
    )}&lat=${lat}&lon=${lng}`;
    const endpoint = "api/location/" + queryString;
    const contentType = isCsvContentType ? "text/csv" : undefined;
    const response = await this.getAuthorizedWithEndpoint(
      endpoint,
      contentType,
    );
    if (isCsvContentType) {
      return await response.blob();
    } else {
      const locationInfos = await response.json();
      const map = new Map<string, any>();
      locationInfos.forEach((elemArr: any[]) => {
        map.set(elemArr[0], elemArr[1]);
      });

      return map;
    }
  }

  public transformAttributeMetaDataDictToInstances(
    plainAttributeMetaDatas: any[],
  ): AttributeMetadata[] {
    const attributeMetaDatas: AttributeMetadata[] = [];
    plainAttributeMetaDatas.forEach(
      (plainAttributeMetaData: AttributeMetadata) => {
        const attributeMetadata = Object.assign(
          new AttributeMetadata(),
          plainAttributeMetaData,
        );
        attributeMetaDatas.push(attributeMetadata);
      },
    );
    return attributeMetaDatas;
  }

  public transformAttributeConfigurationDictToInstances(
    plainAttributeConfigurations: any[],
  ): AttributeConfiguration[] {
    const attributeConfigurations: AttributeConfiguration[] = [];

    try {
      plainAttributeConfigurations.forEach((attributeConfigurationDict) => {
        attributeConfigurationDict.transformation = decodeURIComponent(
          attributeConfigurationDict.transformation,
        );
        attributeConfigurationDict.weight = decodeURIComponent(
          attributeConfigurationDict.weight,
        );

        const attributeConfig = Object.assign(
          new AttributeConfiguration(),
          attributeConfigurationDict,
        );
        attributeConfigurations.push(attributeConfig);
      });
    } catch (Exception) {
      throw Exception;
    } finally {
      return attributeConfigurations;
    }
  }

  private transformUserConfigurationDictsToInstances(
    userConfigurations: any[],
  ): UserConfiguration[] {
    const reconstructedUserConfigurations: UserConfiguration[] = [];
    try {
      userConfigurations.forEach((userConfigurationDict: any) => {
        const userConfiguration = new UserConfiguration();
        userConfiguration.name = userConfigurationDict.name;
        userConfiguration.id = userConfigurationDict.id;
        userConfiguration.configurations = this.transformAttributeConfigurationDictToInstances(
          userConfigurationDict.configurations,
        );
        reconstructedUserConfigurations.push(userConfiguration);
      });
    } catch (Exception) {
      throw Exception;
    } finally {
      return reconstructedUserConfigurations;
    }
  }
}

export { SQMApi };
